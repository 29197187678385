/** Search form */
.search-form {
  @apply flex items-center;
}

.search-form label {
  @apply mb-4;

  font-weight: normal;
}

.search-form .search-field {
  @apply block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-500 rounded;
}

.search-form .search-submit {
  @apply inline-block align-middle text-center select-none border font-normal whitespace-nowrap py-2 px-4 rounded text-base leading-normal no-underline text-gray-100 bg-gray-500;

  &:hover {
    @apply bg-gray-400;
  }
}
