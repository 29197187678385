.comment-list {
  @apply list-none p-0;
}

.comment-list ol {
  list-style: none;
}

.comment-form p {
  @apply mb-4;
}

.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @apply block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-700 border border-gray-500 rounded;
}

.comment-form input[type="submit"] {
  @apply btn bg-gray-600;

  &:hover {
    @apply bg-gray-700;
  }
}
