/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */
@tailwind base;

/** Import variables */
@import "common/variables";

/** Import everything from autoload */
;

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

/**
 * This injects any component classes registered by Tailwind plugins.
 */
@tailwind components;

/** Import theme styles */
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 */
@tailwind utilities;

$jc-red: #590515;
$jc-teal: #02a696;
$jc-green: #39401f;
$jc-cream: #f2efdf;
$jc-black: #0d0d0d;
$jc-seafoam: #a4dad2;
$jc-colonial-blue: #16597d;
$jc-cream: #f0ead8;
$jc-straw: #f2d37e;
$jc-pink: #f58e7d;
$jc-sky-blue: #75adcf;

p {
  font-size: 1.3rem;
}

a {
  border-bottom-width: 0;
  color: #0872ba;
  font-family: "Barlow", sans-serif;

  &:hover {
    color: inherit;
  }
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

body {
  font-family: "Crimson Pro", serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Barlow", sans-serif;
}

@media (min-width: 576px) {
  .alignleft {
    margin-right: 3rem;
    margin-bottom: 3rem;
  }
}
