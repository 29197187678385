.footer {
  background: rgb(51, 54, 56);
}

.content-info {
  position: relative;
  width: 100%;
  clear: both;
  overflow: hidden;
  margin: 0 auto;
}

.foot-address {
  width: 100%;
  padding: 60px 5%;
  margin: 0;
}

.foot-contact {
  background-color: rgb(43, 46, 48);
  width: 100%;
  padding: 60px 5%;
  margin: 0;

  div.wpforms-container-full .wpforms-form .wpforms-submit {
    color: white;
    background-color: #0872ba;
    text-transform: uppercase;
    border: 1px solid #0872ba;
    width: 100%;

    &:hover {
      border: 1px solid white;
      background: #0872ba;
    }
  }
}

.bottom {
  min-height: 50px;
}

.social-share div {
  display: inline-block;
  padding: 0;
  vertical-align: middle;
  cursor: pointer;
}

.social-share {
  span {
    display: none;
  }

  a::after {
    display: inline-block;
    font: 20px "dashicons", sans-serif;
    line-height: 1.3;
  }

  a.facebook::after {
    content: "\f305";
    position: relative;
    top: 6px;
  }

  a.twitter::after {
    content: "\f301";
    position: relative;
    top: 6px;
  }

  a.email::after {
    content: "\f466";
    position: relative;
    top: 6px;
  }
}

span.leading-letter {
  font-size: 2em;
}

@media (min-width: 1200px) {
  .footer {
    background: -moz-linear-gradient(left, rgba(51, 54, 56, 1) 49.49%, rgba(43, 46, 48, 1) 49.51%);
    background: -webkit-linear-gradient(left, rgba(51, 54, 56, 1) 49.49%, rgba(43, 46, 48, 1) 49.51%);
    background: linear-gradient(to right, rgba(51, 54, 56, 1) 49.49%, rgba(43, 46, 48, 1) 49.51%);
  }

  .foot-address,
  .foot-contact {
    width: 50%;
    float: left;
  }

  .bottom-copy {
    order: 1;
  }

  .bottom-social {
    order: 2;
  }

  .bottom-credit {
    order: 3;
  }

  .foot-contact div.wpforms-container-full .wpforms-form .wpforms-submit {
    float: right;
    width: auto;
  }
}
