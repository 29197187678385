$jc-red: #590515;
$jc-teal: #02a696;
$jc-green: #39401f;
$jc-black: #0d0d0d;

$jc-aqua: #054559;

$jc-seafoam: #a4dad2;
$jc-colonial-blue: #16597d;
$jc-cream: #f0ead8;
$jc-straw: #f2d37e;
$jc-pink: #f58e7d;
$jc-sky-blue: #75adcf;

.mobile-menu-icon {
  margin-left: 10px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-right: 0;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.mobile-menu-icon.active {
  display: none;
}

.mobile-menu-icon:hover .lines::before {
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.mobile-menu-icon:hover .lines::after {
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}

.lines {
  background-color: #333;
  display: inline-block;
  width: 22px;
  height: 2px;
  position: relative;
}

.lines::after,
.lines::before {
  display: inline-block;
  width: 22px;
  height: 2px;
  position: absolute;
  background-color: #333;
  top: -7px;
  left: 0;
  content: "";
  -webkit-transform-origin: 1.5px center;
  -ms-transform-origin: 1.5px center;
  transform-origin: 1.5px center;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.lines::before {
  top: 7px;
}

.mobile-menu {
  width: 300px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 30px 30px 0;
  background-color: #fff;
  z-index: 9990;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  transition: right 0.5s ease;
  display: block;

  .sub-menu {
    display: none;
    width: 100%;
    margin-left: 10px;
  }

  .nav {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      flex-flow: row wrap;
      position: relative;

      a {
        padding: 16px 0;
        color: $jc-black;
        text-transform: uppercase;
      }
    }

    .sub-menu {
      a {
        padding: 10px 0;
      }
    }
  }

  a.mobile-icon {
    width: 30px;
    position: relative;
    display: flex;
    flex-grow: 0;
    right: 0;
  }
}

.mobile-menu.show-mobile-menu {
  right: 300px;
}

.close-mobile-menu-icon.show-mobile-close {
  right: 0;
}

.mobile-menu .nav li.menu-item-has-children a:first-child {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: calc(100% - 30px);
}

.mobile-menu .nav > li.menu-item-has-children > a.mobile-icon {
  display: flex;
  flex-grow: 0;
}

li.menu-item-has-children > a {
  max-width: calc(100% - 30px);
  display: flex;
  flex-grow: 1;
}

li.menu-item-has-children > a.mobile-icon {
  flex-grow: 0;
}

.menu-item {
  z-index: 100;
}

.admin-bar .mobile-menu,
.admin-bar .close-mobile-menu-icon {
  top: 47px;
}

.close-mobile-menu-icon {
  position: fixed;
  top: 0;
  width: 30px;
  height: 30px;
  z-index: 9999;
  transition: opacity 0.2s ease;
  transition-delay: 0.5s;
  left: auto;
  right: -240px;

  span {
    color: #fff;
    background-color: $jc-teal;
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0;

    &::before,
    &::after {
      display: inline-block;
      height: 2px;
      background: #fff;
      position: absolute;
      content: "";
      transform-origin: 50% 50%;
      top: 50%;
      left: 50%;
      margin: -1px 0 0 -11px;
      width: 22px;
      transition: transform 0.1s ease;
    }

    &::before {
      transform: rotate3d(0, 0, 1, 45deg);
    }

    &::after {
      transform: rotate3d(0, 0, 1, -45deg);
    }
  }
}

@media (min-width: 783px) {
  .admin-bar .mobile-menu,
  .admin-bar .close-mobile-menu-icon {
    top: 32px;
  }
}

.nav-primary > .nav {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  ul {
    min-width: 150px;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: -999em;
    left: -999em;
    background-color: $jc-aqua;
  }

  li {
    display: inline-block;
    position: relative;
    text-align: left;
    font-family: "Barlow", sans-serif;

    &.focus,
    &:hover {
      > ul {
        top: auto;
        left: auto;
      }
    }

    li {
      display: block;

      &.focus,
      &:hover {
        > ul {
          left: 100%;
          top: 0;
        }
      }
    }
  }

  a {
    color: $jc-cream;
    font-family: "Barlow", sans-serif;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    padding: 15px 25px;
  }
}

.nav-primary.sticky-mobile-on {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  border-top: solid 4px $jc-teal;
  z-index: 9990;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.07);
}

.nav-secondary {
  position: relative;
}

.nav-secondary > .sec-nav {
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    background-color: $jc-aqua;
    border-radius: 0 0 5px 5px;
    margin-right: 5px;

    a {
      color: $jc-cream;
      padding: 10px;
      display: block;
      font-family: "Barlow", sans-serif;

      &:hover {
        color: white;
      }
    }
  }
}

.mobile-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
  visibility: hidden;
}

.mobile-overlay.active {
  opacity: 1;
  visibility: visible;
  z-index: 9900;
}

.wrap {
  padding: 2rem 1rem;
}
